#why_us .container {
    max-width: unset;
    background-color: #caba9c;
    padding: 0;
}

#why_us .item-5 h3 {
    padding: 2em 0 4px 0em;
    margin: 0;
    font-size: 45px;
    line-height: 1em;
    font-weight: 200;
}

#why_us .item-5 h4 {
    font-size: 49px;
    line-height: 1em;
    padding: 0 0 11px 0;
    margin: 0;
}

#why_us .item-5 h5 {
    margin: 0;
    line-height: 1em;
    font-weight: 600;
    letter-spacing: 8px;
    padding: 0 0 10% 0em;
}
#why_us h6{
    font-size: 1.5rem;
    padding: 1em;
}

#why_us p{
    font-size: 1rem;
    padding: 1em;
}


#why_us .item-1, #why_us .item-2, #why_us .item-3, #why_us .item-4, #why_us .item-5 {
    min-height: 300px;
}

#why_us .item-1, #why_us .item-3 {
    background-color: #caba9c;
    
}

#why_us .item-1 h6, #why_us .item-3 h6 {
    color: #8a662f;
    text-transform: uppercase;
    font-weight: 600;

}

#why_us .item-1 p, #why_us .item-3 p {
    color: white;
}



#why_us .item-2, #why_us .item-5 {
    background-color: #f2f2f2;
}

#why_us .item-4 {
    background-color: #161515;
}

#why_us .item-img {
    min-height: 600px;
    background-image: url('../../../public/assets/images/smile1.jpg');
    background-position: 50% 50%;
}
