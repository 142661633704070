footer a {
    color: black;
}

.contact-content img {
    max-width: 100%;
    height: auto;
}

.contact-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.contact-area {
    color: #868c96;
    background: #1A1E25;
    padding: 60px 0;
    border-bottom: 1px solid #353C46;
}

.contact-content p {
    font-size: 15px;
    margin: 30px 0 60px;
    position: relative;
}

.contact-content .phone-numbers>a {
    text-decoration: none;
    color: #399ab0;
    opacity: .7;
}

.contact-content .phone-numbers>a:hover {
    opacity: 1;
}

.contact-content p::after {
    background: #353C46;
    bottom: -30px;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translate(-50%);
    width: 80%;
}

.contact-content h6 {
    color: #8b9199;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
}

.contact-content span {
    color: #353c47;
    margin: 0 10px;
}


.contact-social {
    margin-top: 30px;
}



.contact-social>ul {
    display: inline-flex;
}

.contact-social ul li a {
    text-decoration: none;
    min-width: 103px;
    color: #8b9199;
    display: inline-block;
    list-style: none;
    margin: 0 .1rem;
    padding: .1rem .7rem;
    transition: all 0.4s ease 0s;
    cursor: pointer;
}

.contact-social ul li a:hover {
    color: white;
    box-shadow: 0px 3px 0px 0px #caba9c;
}

.contact-social ul li svg {
    display: none;
}

.contact-content img {
    max-width: 210px;
}

footer {
    padding-top: 5em;
    background: #1A1E25;
    color: #868c96;
}

footer p {
    margin-top: 0px !important;
    padding: 40px 0;
    text-align: center;
}

footer img {
    /* width: 44px; */
}


@media (max-width: 575px) {
    .contact-social {
        text-align: left !important;
    }

    .contact-social>ul {
        flex-direction: column;
    }

    .contact-social ul {
        margin-left: 2rem;
    }

    .contact-social ul li svg {
        display: inline-block;
    }

    .contact-social ul li a {
        margin: 0.1em 0;
        padding: 0.2rem 0.1rem;
    }


}

@media (max-width: 767px) {}