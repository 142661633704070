@charset "UTF-8";

.pricingTable {
  /* margin: 40px auto; */
  padding: 3em  0;
}

.pricingTable>.pricingTable-title {
  text-align: center;
  color: #caba9c;
  font-size: 2.5em;
  /* font-size: 300%; */
  margin-bottom: 20px;
  letter-spacing: 0.01em;
}

.pricingTable>.pricingTable-subtitle {
  text-align: center;
  color: #b4bdc6;
  font-size: 1.8em;
  letter-spacing: 0.04em;
  margin-bottom: 60px;
}

@media screen and (max-width: 480px) {
  .pricingTable>.pricingTable-subtitle {
    margin-bottom: 30px;
  }
}

.pricingTable-firstTable {
  list-style: none;
  padding-left: 2em;
  padding-right: 2em;
  text-align: center;
}

.pricingTable-firstTable_table {
  vertical-align: middle;
  width: 31%;
  background-color: #ffffff;
  display: inline-block;
  padding: 0px 30px 40px;
  text-align: center;
  max-width: 320px;
  transition: all 0.3s ease;
  border-radius: 5px;
}


@media screen and (min-width: 768px) {
  .table-plat {
    margin-top: 2em;
    width: 65% !important;
    max-width: unset;
  }
}


@media screen and (max-width: 767px) {
  .pricingTable-firstTable_table {
    display: block;
    width: 90%;
    margin: 0 auto;
    max-width: 90%;
    margin-bottom: 20px;
    padding: 10px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .pricingTable-firstTable_table>* {
    display: inline-block;
    vertical-align: middle;
  }
}

@media screen and (max-width: 480px) {
  .pricingTable-firstTable_table>* {
    display: block;
    float: none;
  }
}

@media screen and (max-width: 767px) {
  .pricingTable-firstTable_table:after {
    display: table;
    content: "";
    clear: both;
  }
}

.pricingTable-firstTable_table:hover {
  transform: scale(1.08);
}

@media screen and (max-width: 767px) {
  .pricingTable-firstTable_table:hover {
    transform: none;
  }
}

.pricingTable-firstTable_table:not(:last-of-type) {
  margin-right: 3.5%;
}

@media screen and (max-width: 767px) {
  .pricingTable-firstTable_table:not(:last-of-type) {
    margin-right: auto;
  }
}

.pricingTable-firstTable_table__header {
  font-size: 1.6em;
  padding: 40px 0px;
  border-bottom: 2px solid #ebedec;
  letter-spacing: 0.03em;
}

@media screen and (max-width: 1068px) {
  .pricingTable-firstTable_table__header {
    font-size: 1.45em;
  }
}

@media screen and (max-width: 767px) {
  .pricingTable-firstTable_table__header {
    padding: 0px;
    border-bottom: none;
    float: left;
    width: 33%;
    /* padding-top: 3%; */
    padding-bottom: 2%;
  }
}

@media screen and (max-width: 610px) {
  .pricingTable-firstTable_table__header {
    font-size: 1.3em;
  }
}

@media screen and (max-width: 480px) {
  .pricingTable-firstTable_table__header {
    float: none;
    width: 100%;
    font-size: 1.8em;
    margin-bottom: 5px;
  }
}

.pricingTable-firstTable_table__pricing {
  font-size: 3em;
  padding: 30px 0px;
  border-bottom: 2px solid #ebedec;
  line-height: 0.7;
}

@media screen and (max-width: 1068px) {
  .pricingTable-firstTable_table__pricing {
    font-size: 2.8em;
  }
}

@media screen and (max-width: 767px) {
  .pricingTable-firstTable_table__pricing {
    border-bottom: none;
    padding: 0;
    float: left;
    clear: left;
    width: 33%;
  }
}

@media screen and (max-width: 610px) {
  .pricingTable-firstTable_table__pricing {
    font-size: 2.4em;
  }
}

@media screen and (max-width: 480px) {
  .pricingTable-firstTable_table__pricing {
    float: none;
    width: 100%;
    font-size: 3em;
    margin-bottom: 10px;
  }
}

.pricingTable-firstTable_table__pricing span:last-of-type {
  font-size: 0.35em;
  vertical-align: top;
  letter-spacing: 0.15em;
}

@media screen and (max-width: 1068px) {
  .pricingTable-firstTable_table__pricing span:last-of-type {
    font-size: 0.3em;
  }
}

/* .pricingTable-firstTable_table__pricing span:last-of-type {
  vertical-align: bottom;
  font-size: 0.3em;
  letter-spacing: 0.04em;
  padding-left: 0.2em;
}
@media screen and (max-width: 1068px) {
  .pricingTable-firstTable_table__pricing span:last-of-type {
    font-size: 0.25em;
  }
} */
.pricingTable-firstTable_table__options {
  list-style: none;
  padding: 15px;
  font-size: 0.9em;
  border-bottom: 2px solid #ebedec;
}

@media screen and (max-width: 1068px) {
  .pricingTable-firstTable_table__options {
    font-size: 0.85em;
    padding: 0;
    /* min-height: 150px; */
  }
}

@media screen and (max-width: 767px) {
  .pricingTable-firstTable_table__options {
    max-width: 150px;
    border-bottom: none;
    padding: 0;
    margin-right: 10%;
  }
}

@media screen and (max-width: 610px) {
  .pricingTable-firstTable_table__options {
    font-size: 0.7em;
    margin-right: 8%;
  }
}

@media screen and (max-width: 480px) {
  .pricingTable-firstTable_table__options {
    font-size: 1.3em;
    margin: 0 auto;
    transform: translateX(-7.5px);
    margin-bottom: 10px;
  }
}

.pricingTable-firstTable_table__options>li {
  padding: 8px 0px;
  min-width: 175px;
}

@media screen and (max-width: 767px) {
  .pricingTable-firstTable_table__options>li {
    text-align: left;
  }
}

@media screen and (max-width: 610px) {
  .pricingTable-firstTable_table__options>li {
    padding: 5px 0;
  }
}

@media screen and (max-width: 480px) {
  .pricingTable-firstTable_table__options>li {
    text-align: center;
  }
}

.pricingTable-firstTable_table__options>li:not(.not-before):before {
  content: "✓";
  display: inline-flex;
  margin-right: 15px;
  color: white;
  background-color: #caba9c;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 0.8em;
  padding: 2px;
  align-items: center;
  justify-content: center;
}


@media screen and (min-width: 768px) {
  .pricingTable-firstTable_table__options>li.not-before:before {
    content: "";
    display: inline-flex;
    margin-right: 15px;
    color: white;
    background-color: transparent;
    border-radius: 50%;
    width: 100px;
    height: 15px;
    font-size: 0.8em;
    padding: 2px;
    align-items: center;
    justify-content: center;
  }
}


@media screen and (max-width: 1068px) {
  .pricingTable-firstTable_table__options>li:before {
    width: 14px;
    height: 14px;
    padding: 1.5px;
  }
}

@media screen and (max-width: 767px) {
  .pricingTable-firstTable_table__options>li:before {
    width: 12px;
    height: 12px;
  }
}

.pricingTable-firstTable_table__getstart {
  color: white;
  border: 0;
  background-color: #caba9c;
  margin-top: 30px;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px;
  letter-spacing: 0.07em;
  transition: all 0.4s ease;
}

@media screen and (max-width: 1068px) {
  .pricingTable-firstTable_table__getstart {
    font-size: 0.95em;
  }
}

@media screen and (max-width: 767px) {
  .pricingTable-firstTable_table__getstart {
    margin-top: 0;
    float: right;
  }
}

@media screen and (max-width: 610px) {
  .pricingTable-firstTable_table__getstart {
    font-size: 0.9em;
    float: right;
    padding: 10px;
  }
}

@media screen and (max-width: 480px) {
  .pricingTable-firstTable_table__getstart {
    font-size: 1em;
    width: 50%;
    float: unset;
    margin: 10px auto;
  }
}

.pricingTable-firstTable_table__getstart:hover {
  /* transform: translateY(-10px); */
  box-shadow: 0px 7px 6px 0px rgb(53 64 53 / 29%);

}

@media screen and (max-width: 767px) {
  .pricingTable-firstTable_table__getstart:hover {
    transform: none;
    box-shadow: none;
  }
}

.pricingTable-firstTable_table__getstart:active {
  box-shadow: inset 0 0 10px 1px #caba9c, 0px 40px 29px -19px #caba9c;
  transform: scale(0.95) translateY(-9px);
}

@media screen and (max-width: 767px) {
  .pricingTable-firstTable_table__getstart:active {
    transform: scale(0.95) translateY(0);
    box-shadow: none;
  }
}


.pricingTable {
  font-family: "Montserrat", sans-serif;
  font-size: 100%;
  background-color: #f2f2f2;
  color: #717787;

}

@media screen and (max-width: 960px) {
  .pricingTable {
    font-size: 80%;
  }
}

@media screen and (max-width: 776px) {
  .pricingTable {
    font-size: 70%;
  }
}

@media screen and (max-width: 496px) {
  .pricingTable {
    font-size: 50%;
  }
}

@media screen and (max-width: 320px) {
  .pricingTable {
    font-size: 40%;
  }
}