#contact-us {

    font-family: "Montserrat", sans-serif;
    background-color: #f2f2f2;
}

#contact-us .wrapper {

    padding-bottom: 2em;
    /* min-height: 100vh; */
    padding: 1em auto;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
}

#contact-us .inner {
    box-shadow: 0px 0px 13px #9d9d9d87;
    min-width: 950px;
    margin: auto;
    padding-top: 68px;
    padding-bottom: 48px;
    background: url("../../../public/assets/images/smile-contact.jpg");
    background-position-x: 100%;
    background-repeat: no-repeat;
    background-size: 50% 100%;
}


.inner h3 {
    text-transform: uppercase;
    font-size: 22px;
    text-align: center;
    margin-bottom: 32px;
    color: #333;
    letter-spacing: 2px;
}

#contact-us form {
    width: 50%;
    padding-left: 45px;
}

#contact-us .form-group {
    display: flex;
}

.form-group .form-wrapper {
    width: 50%;
}

.form-group .form-wrapper:first-child {
    margin-right: 20px;
}

#contact-us .form-wrapper {
    margin-bottom: 17px;
}

.form-wrapper label {
    margin-bottom: 9px;
    display: block;
}

#contact-us form {
    padding: 1em;
}

#contact-us .form-control {
    border: 1px solid #ccc;
    display: block;
    width: 100%;
    height: 40px;
    padding: 0 20px;
    border-radius: 20px;
    font-family: "Muli-Bold";
    background: none;
}

.form-control[type=textarea] {
    height: 100px !important;
}

.form-control:focus {
    border: unset;
    box-shadow: 0 0 0 .15rem #cbb9898a;
}

#contact-us select {
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    padding-left: 20px;
}

select option[value=""][disabled] {
    display: none;
}

#contact-us button {
    border: none;
    width: 152px;
    height: 40px;
    margin: auto;
    margin-top: 29px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: #cbb989;
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
    font-family: "Muli-SemiBold";
    border-radius: 20px;
    overflow: hidden;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
}

#contact-us button:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #8a662f;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

#contact-us button:hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

#contact-us .checkbox {
    position: relative;
}

#contact-us .checkbox label {
    padding-left: 22px;
    cursor: pointer;
}

#contact-us .checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

#contact-us .checkbox input:checked~.checkmark:after {
    display: block;
}

#contact-us .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 12px;
    width: 13px;
    border-radius: 2px;
    background-color: #ebebeb;
    border: 1px solid #ccc;
    font-family: Material-Design-Iconic-Font;
    color: #000;
    font-size: 10px;
    font-weight: bolder;
}

.checkmark:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    content: '\f26b';
}

@media (max-width: 991px) {
    #contact-us .inner {
        min-width: 768px;
        background-position: 110%;
        background-size: 60% 100%;
    }

    #contact-us .form-wrapper {
        width: 100%;
    }

    #contact-us form {
        width: 45%
    }

    #contact-us .form-group {
        display: block;
    }
}

@media (max-width: 767px) {
    #contact-us .inner {
        min-width: auto;
        background: none;
        padding-top: 0;
        padding-bottom: 0;
    }

    #contact-us form {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }

    #contact-us .form-group {
        display: flex;
    }
}


.sent-error,.sent-success{
    width: 50%;
    margin: 0 auto;
    padding: 1em;
    border-radius: 10px;
    color: white;
}

.sent-error{
    background: #7f1f1f;
}
.sent-success{
    background: #cbb989 ;
}
/*# sourceMappingURL=style.css.map */